let mdApiUrl = window._env.REACT_APP_MD_API_URL


if (!mdApiUrl.endsWith('/')) {
    mdApiUrl += '/'
}

const config = {
    url: {
        v1: {
            API_URL: mdApiUrl,
            API_URL_LOGIN: mdApiUrl + 'api/v1/auth/login',
            AUTH: {
                CHANGE_PASS: mdApiUrl + 'api/v1/auth/password'
            },
            MESSAGE: {
                GET: mdApiUrl + 'api/v1/msg',
                SAVE: mdApiUrl + 'api/v1/msg/save'
            }
        }
    }
}

export const apiConfig = config
