import React, {useContext} from 'react'
import {Redirect, Route} from 'react-router-dom'
import {AuthContext} from "../../providers/auth/AuthContext"

function ManagerRoute({ component: Component, ...rest }) {
    let allowed = false

    const {authenticated, user} = useContext(AuthContext)

    if (!authenticated || !user) {
        return (
            <Redirect to="/login" />
        )
    }


    try {
        allowed = user.roles.includes('ROLE_MANAGER')
    } catch (e) {
        allowed = false
    }

    return (
        <Route
            {...rest}
            render={props =>
                allowed ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    )
}

export default ManagerRoute
