import React, {useContext} from "react";
import {AuthContext} from "../providers/auth/AuthContext";
import {Redirect} from "react-router";

function IndexPage() {
    const {authenticated, user} = useContext(AuthContext)

    if (authenticated && user.roles && user.roles.includes('ROLE_MANAGER')) {
        return (
            <Redirect to="/filter" />
        )
    }

    if (authenticated) {
        return (
            <Redirect to="/home" />
        )
    }
    return (
        <Redirect to="/login" />
    )
}

export default IndexPage
