import React, {useContext, useEffect, useState} from "react"
import AdminLayout from "../../components/Admin/AdminLayout"
import {Button, ButtonGroup, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap"
import Loader from "react-loader-spinner"
import API from "../../utils/API"
import { apiConfig } from "../../constants/api"
import { useToasts } from "react-toast-notifications"
import './filter.css'

const getSensitivityText = (score) => {
    if (score === 2) {
        return '❌ Original reply sensitivity is high. Please provide the correct answer:'
    }
    return '✅ Original reply sensitivity is fine.'
}

const FilterPage = () => {

    const [context, setContext] = useState(undefined)
    const [message, setMessage] = useState(undefined)
    const [reply, setReply] = useState(undefined)
    const [uid, setUid] = useState(undefined)
    const [sensitivity, sentSensitivity] = useState(undefined)

    const [isEditing, setIsEditing] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const { addToast } = useToasts()
    let timer

    const startPolling = () => {
        timer = setInterval(() => {
            getMessage()
        }, 1000)
    }

    const stopPolling = () => {
        clearInterval(timer)
    }

    const getMessage = () => {
        // console.log('getting message')
        API.get(apiConfig.url.v1.MESSAGE.GET, { timeout: 3000 })
            .then((res) => {
                if (!res.data) {
                    return
                }
                stopPolling()
                setMessage(res.data.input)
                sentSensitivity(res.data.sensitivity)
                setUid(res.data.uid)
                setContext(res.data.context)
                if (res.data.sensitivity === 2) {
                    setReply(undefined)
                    declineMessage()
                } else {
                    setReply(res.data.output)
                }
            }).catch((err) => {
                console.log(err)
                // addToast('Error fetching new message', { appearance: 'error', autoDismiss: true})
        })
    }

    const approveMessage = () => {
        setIsLoading(true)
        API.post(apiConfig.url.v1.MESSAGE.SAVE, { input: message, output: reply, uid } ,{ timeout: 3000 })
            .then((res) => {
                setMessage(undefined)
                setReply(undefined)
                setUid(undefined)
                setContext(undefined)
                sentSensitivity(undefined)
                setIsEditing(false)
                startPolling()
            }).catch((err) => {
                console.log(err)
                addToast('Error saving message', { appearance: 'error', autoDismiss: true})
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        startPolling()
        return () => { stopPolling() }
    }, [])

    const handleReplyChange = (e) => {
        setReply(e.target.value)
    }

    const declineMessage = () => {
        addToast('Please provide the correct answer', { appearance: 'success', autoDismiss: true})
        setIsEditing(true)
    }

    if (isLoading) {
        return (
            <AdminLayout>
                <Row className="justify-content-center">
                    <Loader color="#00BFFF"/>
                </Row>
            </AdminLayout>
        )
    }

    if (!message) {
        return (
            <AdminLayout>
                <Form>
                    <h3>Filter messages</h3>
                    <p>Waiting for a new message...</p>
                </Form>
            </AdminLayout>
        )
    }

    return (
        <AdminLayout>
                    <Form>
                        <h3>Filter messages</h3>

                        <FormGroup role="form">
                            <Label>Context</Label>
                            <Input className="txt-extended" type="textarea" name="system-context" value={context} disabled />
                        </FormGroup>

                        <FormGroup role="form">
                            <Label>User input</Label>
                            <Input type="textarea" name="user-input" value={message} disabled />
                        </FormGroup>

                        <FormGroup role="form">
                            <Label>Original reply sensitivity: {String(sensitivity)}</Label>
                            <p>{getSensitivityText(sensitivity)}</p>
                        </FormGroup>
                        <FormGroup role="form">
                            <Label>System reply</Label>
                            <Input type="textarea" name="system-reply" value={reply} onChange={handleReplyChange} disabled={!isEditing} />
                        </FormGroup>

                        {!isEditing && (
                            <ButtonGroup size="lg" className="mb-2">
                                <Button color="success" onClick={approveMessage} >Approve</Button>
                                <Button color="secondary" disabled>OR</Button>
                                <Button color="danger" onClick={declineMessage} >Decline</Button>
                            </ButtonGroup>
                        )}

                        {(isEditing && reply) && (
                            <ButtonGroup size="lg" className="mb-2">
                                <Button color="success" onClick={approveMessage} >Approve</Button>
                            </ButtonGroup>
                        )}
                    </Form>
        </AdminLayout>
    )
}

export default FilterPage
