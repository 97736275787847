import React, {useEffect, useState} from "react";

import API from "../../utils/API";
import { apiConfig } from "../../constants/api";

import Loader from 'react-loader-spinner'

import AdminLayout from "../../components/Admin/AdminLayout";

import './main.css'
import {Button, Row} from "reactstrap";

const HomePage = () => {
    return (
        <AdminLayout>
            <h2>Main page</h2>
        </AdminLayout>
    )
}

export default HomePage
