import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {AuthContext} from "./providers/auth/AuthContext";

function UserRoute({ component: Component, ...rest }) {
    let allowed = false

    const {authenticated, user} = useContext(AuthContext)

    if (!authenticated || !user) {
        return (
            <Redirect to="/login" />
        )
    }

    // const roles = user.roles

    // let isAdmin = roles.includes('ROLE_USER')
    allowed = true

    return (
        <Route
            {...rest}
            render={props =>
                allowed ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
}

export default UserRoute;
