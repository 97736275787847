import React from "react"
import { Route, Switch } from 'react-router'
import LoginPage from "../pages/login/LoginPage"
import IndexPage from "../pages/IndexPage"
import PrivateRoute from "../PrivateRoute"
import Logout from "./Logout"
import UserRoute from "../UserRoute"
import HomePage from "../pages/home/HomePage"
import ChangePasswordPage from "../pages/password/ChangePasswordPage"
import ManagerRoute from "./Route/ManagerRoute";
import FilterPage from "../pages/filter/FilterPage";


const AppRouter = () => {
    return (
        <Switch>
            <Route exact path="/" component={IndexPage} />

            <UserRoute exact path="/home" component={HomePage} />
            <ManagerRoute exact path="/filter" component={FilterPage} />

            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute exact path="/password" component={ChangePasswordPage} />

            <Route path={["/login", "/register"]}>
                <div className="auth-wrapper">
                    <div className="auth-inner">
                        <Route exact path="/login" component={LoginPage} />
                    </div>
                </div>
            </Route>

        </Switch>
    )
}

export default AppRouter

